import useSWR, { mutate } from 'swr';
import {
  Account,
  AccountChangePasswordResponse,
  AccountLoginBody,
  AccountMigrationLoginBody,
  AccountMigrationLoginResponse,
  AccountRegisterBody,
  AccountRegisterResponse,
  AccountRequestPasswordResetBody,
  AccountResetPasswordBody,
  AccountResetPasswordResponse,
  AccountUpdateBody,
  AccountUpdateResponse,
  AccountWebserviceUpdateResponse,
  GetAccountResult,
  GetSerializedAccountResult,
  NewsletterIdentifier,
  SerializedAccount,
} from '@frontastic-engbers/types/account/Account';
import { Address } from '@frontastic-engbers/types/account/Address';
import { Cart } from '@frontastic-engbers/types/cart/Cart';
import { CheckoutData, CustomerOrigin, PaymentMethod } from '@frontastic-engbers/types/engbers-custom';
import { REMEMBER_ME } from '@frontastic-engbers/helpers/constants/localStorage';
import { fetchApiHub, revalidateOptions } from '../../';
import { CustomerSetCustomFieldAction } from '@commercetools/platform-sdk/dist/declarations/src/generated/models/customer';

export * from './useVouchers';
export * from './useUsedCoupons';
export * from './useOrderHistory';

const deserializeAccount = (account: SerializedAccount): Account => {
  return {
    ...account,
    birthday: new Date(account.birthday),
  };
};

export const getAccount = (): GetAccountResult => {
  const result = useSWR<GetSerializedAccountResult>('/action/account/getAccount', fetchApiHub, revalidateOptions);
  let isWebserviceUpdated = false;
  let isAccountUpdateFailed = false;

  if (!result.data?.account) {
    return {
      loaded: result.data !== undefined,
      loggedIn: false,
      account: undefined,
      status: result.data?.status,
    };
  }

  if (result.data?.webserviceUpdated) {
    isWebserviceUpdated = true;
  }

  if (result.data?.webserviceAccountUpdateFailed) {
    isAccountUpdateFailed = true;
  }

  return {
    loaded: true,
    webserviceUpdated: isWebserviceUpdated,
    webserviceAccountUpdateFailed: isAccountUpdateFailed,
    account: deserializeAccount(result.data.account),
    loggedIn: true,
  };
};

export const login = async (email: string, password: string, isPwa?: boolean): Promise<GetAccountResult> => {
  // Commercetools default implementation:
  // const res = await fetchApiHub(
  //   '/action/account/login',
  //   { method: 'POST' },
  //   { email, password },
  // );
  // await mutate('/action/account/getAccount', res);
  // await mutate('/action/wishlist/getWishlist');
  // return res;
  const res: GetAccountResult = await fetchApiHub('/action/account/login', { method: 'POST' }, <AccountLoginBody>{
    email,
    password,
    isPwa,
  });

  if ('loggedIn' in res && res.loggedIn === false) {
    return res;
  }

  await mutate('/action/account/getAccount', { account: res.account });
  await mutate('/action/cart/getCart');
  await mutate('/action/wishlist/getWishlist');

  return {
    account: res.account,
    loaded: true,
    loggedIn: true,
  };
};

export const migrationLogin = async (cardId: string, birthdate: string): Promise<AccountMigrationLoginResponse> => {
  const body = <AccountMigrationLoginBody>{
    cardId,
    birthdate,
  };
  const res: AccountMigrationLoginResponse = await fetchApiHub(
    '/action/account/migrationLogin',
    { method: 'POST' },
    body,
  );

  return res;
};

export const logout = async () => {
  window.localStorage.removeItem(REMEMBER_ME);
  const res = await fetchApiHub('/action/account/logout', { method: 'POST' });
  await mutate('/action/account/getAccount', {});
  await mutate('/action/cart/getCart');
  await mutate('/action/wishlist/getWishlist');
  return res;
};

export const register = async (body: AccountRegisterBody): Promise<AccountRegisterResponse> => {
  const res = await fetchApiHub('/action/account/register', { method: 'POST' }, body);

  if (res.account) {
    await mutate('/action/account/getAccount', { account: res.account });
  }

  return res;
};

export const confirm = async (token: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/confirm', { method: 'POST' }, { token });
  await mutate('/action/account/getAccount', res);
  return res;
};

export const requestConfirmationEmail = async (email: string, password: string): Promise<void> => {
  const payload = {
    email,
    password,
  };
  const res = await fetchApiHub('/action/account/requestConfirmationEmail', { method: 'POST' }, payload);
  return res;
};

export const changePassword = async (
  oldPassword: string,
  newPassword: string,
): Promise<AccountChangePasswordResponse> => {
  return await fetchApiHub(
    '/action/account/password',
    { method: 'POST' },
    {
      oldPassword,
      newPassword,
    },
  );
};

export const requestPasswordReset = async (email: string): Promise<void> => {
  const payload: AccountRequestPasswordResetBody = {
    email,
  };

  await fetchApiHub('/action/account/requestPasswordReset', { method: 'POST' }, payload);
};

export const resetPassword = async (token: string, newPassword: string): Promise<AccountResetPasswordResponse> => {
  const payload: AccountResetPasswordBody = {
    token,
    newPassword,
  };
  const res: AccountResetPasswordResponse = await fetchApiHub('/action/account/reset', { method: 'POST' }, payload);

  if (res.account) {
    await mutate('/action/account/getAccount', { account: res.account });
  }

  return res;
};

export const update = async (account: AccountUpdateBody, isCheckout = false): Promise<Account> => {
  const res: AccountUpdateResponse = await fetchApiHub(
    '/action/account/update',
    { method: 'POST' },
    {
      account,
      isCheckout,
    },
  );

  await mutate('/action/account/getAccount', { account: res.account });

  return deserializeAccount(res.account);
};

export const updateAccountWithWebservice = async (
  account: Account,
  isCheckout = false,
): Promise<AccountWebserviceUpdateResponse> => {
  const res: AccountWebserviceUpdateResponse = await fetchApiHub(
    '/action/account/updateAccountWithWebservice',
    { method: 'POST' },
    {
      account,
      isCheckout,
    },
  );

  if (res.account) {
    await mutate('/action/account/getAccount', {
      account: res.account,
      webserviceUpdated: true,
      webserviceAccountUpdateFailed: res.webserviceAccountUpdateFailed,
    });
  }

  return res;
};

export const addAddress = async (address: Omit<Address, 'addressId'>): Promise<Account> => {
  const res = await fetchApiHub('/action/account/addAddress', { method: 'POST' }, address);
  await mutate('/action/account/getAccount', res);
  return res;
};

export const updateAddress = async (address: Address): Promise<Account> => {
  const res = await fetchApiHub('/action/account/updateAddress', { method: 'POST' }, address);
  await mutate('/action/account/getAccount', res);
  return res;
};

export const removeAddress = async (addressId: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/removeAddress', { method: 'POST' }, { addressId });
  await mutate('/action/account/getAccount', res);
  return res;
};

export const setDefaultBillingAddress = async (addressId: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/setDefaultBillingAddress', { method: 'POST' }, { addressId });
  await mutate('/action/account/getAccount', res);
  return res;
};

export const setDefaultShippingAddress = async (addressId: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/setDefaultShippingAddress', { method: 'POST' }, { addressId });
  await mutate('/action/account/getAccount', res);
  return res;
};

export const updateNewsletterSubscriptions = async (newsletterSubscriptions: {
  [key in NewsletterIdentifier]: boolean;
}): Promise<void> => {
  await mutate(
    '/action/account/getNewsletterSubscriptions',
    { subscriptions: newsletterSubscriptions },
    {
      optimisticData: { subscriptions: newsletterSubscriptions },
      revalidate: false,
    },
  );

  await fetchApiHub(
    '/action/account/updateNewsletterSubscriptions',
    { method: 'POST' },
    { subscriptions: newsletterSubscriptions },
  );
};

export const userExists = async (email: string): Promise<boolean> => {
  return await fetchApiHub('/action/account/userExists', { method: 'POST' }, { email: email });
};

export const changeEmail = async (email: string, password: string) => {
  return await fetchApiHub(
    '/action/account/updateEmail',
    { method: 'POST' },
    {
      email: email,
      password: password,
    },
  );
};

export const setPwaInstallStatus = async () => {
  const res = await fetchApiHub('/action/account/setPwaInstallStatus', { method: 'POST' });

  if (res.account) {
    await mutate('/action/account/getAccount', { account: res.account });
  }

  return res;
};

export const setLastAppActivity = async () => {
  const res = await fetchApiHub('/action/account/trackLastAppActivity', { method: 'POST' });

  if (res.account) {
    await mutate('/action/account/getAccount', { account: res.account });
  }

  return res;
};

export const getCustomerPaymentMethods = async (
  paymentMethods: PaymentMethod[],
  checkoutData: CheckoutData,
  accountData: Account,
  solvencyCheckAccepted: boolean,
  customerOrigin: CustomerOrigin,
  cartData: Cart,
) => {
  return await fetchApiHub(
    '/action/account/getCustomerPaymentMethods',
    { method: 'POST' },
    {
      paymentMethods,
      checkoutData,
      accountData,
      solvencyCheckAccepted,
      customerOrigin,
      cartData,
    },
  );
};

export const setCustomerCustomFields = async (customFieldActions: CustomerSetCustomFieldAction[]) => {
  const res = await fetchApiHub('/action/account/setCustomerCustomFields', { method: 'POST' }, { customFieldActions });

  await mutate('/action/account/getAccount', res);
  return res;
};
